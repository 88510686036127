<template>
  <div id="digitalView" class="viewComp">
    <div class="main">
      <h1>Digital отдел</h1>
      <div class="texts">
        <p>Нужна реализация интернет-компании на 360°? Тогда вы по адресу!</p>
        <p>
          Планируем, разрабатываем и реализуем стратегии интернет-компании с
          нуля.
        </p>
        <p>
          Мы найдем вашу целевую аудиторию, в какой бы сфере вы ни работали:<br />
          финансы, недвижимость, бытовая техника, телекоммуникации, FMCG,
          электронная и традиционная коммерция и в других сферах.
        </p>
        <p>
          Наша команда реализует умные имиджевые кампании и CPA-инициативы.<br />
          Компетенция наших digital-специалистов подтверждена многолетними
          результатами работы и ежемесячным выполнением KPI.
        </p>
        <p>
          Наши диджитал специалисты ежегодно пордтверждаем сертификацию
          Facebook, myTarget, Google и Yandex, включая Analytics и Метрику.
        </p>
        <p>
          Работаем над оптимизацией цены и при этом увеличении качественных
          показателей в рекламных кампаниях
        </p>
        <p>Нужная мощная интернет-компания? В два счета!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Digital",
};
</script>
