<template>
  <div id="clientView" class="viewComp">
    <div class="main">
      <h1>Client Service Department</h1>
      <div class="texts">
        <p class="norm">
          Мы аккаунт-менеджеры — мы находим «боль» клиента и предлагаем
          правильное лечение: управление проектами, коммуникация, координация,
          контроль и мониторинг.
        </p>
        <p>
          Мы не просто передатчик информации! На нас возложена самая
          ответственная миссия: представлять интересы заказчика и связывать
          между собой клиента и специалистов агентства.
        </p>
        <p>Наши обязанности:</p>
        <ul>
          <li>полное погружение в проект и особенности клиентского бизнеса;</li>
          <li>правильное определение целей проекта;</li>
          <li>отслеживание плана работ;</li>
          <li>общение с клиентом на его языке;</li>
          <li>проведение деловых встреч и переговоров;</li>
          <li>анализ результатов работы над проектом;</li>
          <li>помощь команде в понимании ценностей клиента;</li>
          <li>оперативное предоставление информации;</li>
          <li>
            следование временным рамкам проекта — дедлайн для нас не пустой
            звук;
          </li>
          <li>документооборот между клиентом и агентством.</li>
        </ul>
        <p class="norm">Мы решим все ваши вопросы!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Client",
};
</script>
